import * as React from "react";

const Expertise = () => {
  return (
    <section className="expertise">
      <div className="container">
        <header className="expertise-header">
          <h2>My Web Development Expertise</h2>
          <p>
            I graduaed as a Software Engineer in 2020. It’s a vast field but my
            area of expertise is web development. I follow best practices that
            result in good user experience.
          </p>
        </header>
        <main>
          <article>
            <header>
              <h3>Responsiveness</h3>
            </header>
            <p>
              Users surf the web on different types of devices including
              smartphones, tablets, and desktops. Your website will look equally
              awesome on all devices.
            </p>
          </article>
          <article>
            <header>
              <h3>Accessibility</h3>
            </header>
            <p>
              People who depend on screen readers for internet surfing will also
              have an acceptable experience visiting your website.
            </p>
          </article>
          <article>
            <header>
              <h3>Performance</h3>
            </header>
            <p>
              I take measures to make web pages load as fast as possible so that
              your visitors don’t have to wait to benefit from your content or
              make a purchase.
            </p>
          </article>
          <article>
            <header>
              <h3>Technical SEO</h3>
            </header>
            <p>
              Search engine bots will have an easier time crawling and indexing
              your site because of my well-written markup and other best SEO
              practices.
            </p>
          </article>
          <article>
            <header>
              <h3>Clean UI</h3>
            </header>
            <p>
              Clean, minimalist, and modern aesthetics will make your brand look
              more authentic and can result in users spending more time on your
              site.
            </p>
          </article>
          <article>
            <header>
              <h3>Clean Code</h3>
            </header>
            <p>
              As your project grows, you'll want to hire more developers. If you
              do, they will appreciate my clean and easier to maintain code.
            </p>
          </article>
        </main>
      </div>
    </section>
  );
};

export default Expertise;
