import * as React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { PrimaryButton, SecondaryButton } from "./Buttons";
import { Link, useStaticQuery, graphql } from "gatsby";

const Content = () => {
  const posts = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { featured: { eq: "1" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              snippet
              slug
              meta_description
              hero_image {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.77)
                }
              }
              hero_image_alt
              featured
            }
          }
        }
      }
    }
  `);
  return (
    <section className="content">
      <div className="container">
        <div className="content-wrapper">
          <section className="content-articles">
            <header>
              <div className="col">
                <h2>Articles</h2>
                <p>
                  I write about stuff I’m interested in including custom PCs,
                  books, programming, and technology in general.
                </p>
              </div>
              <div className="col">
                <PrimaryButton>Blog</PrimaryButton>
              </div>
            </header>
            <main>
              {posts.allMdx.edges.map(
                (post) =>
                  post.node.frontmatter.type == "blog" && (
                    <article key={post.node.id}>
                      <GatsbyImage
                        image={getImage(post.node.frontmatter.hero_image)}
                        alt={post.node.frontmatter.hero_image_alt}
                      />
                      <h3>
                        <Link to={"#"}>{post.node.frontmatter.title}</Link>
                      </h3>
                      <p>{post.node.frontmatter.snippet}</p>
                    </article>
                  )
              )}
            </main>
          </section>
          <section className="content-projects">
            <header>
              <div className="col">
                <h2>Projects</h2>
                <p>
                  Here are some projects that I've built from scratch to get an
                  idea about the quality of work you can expect if you choose to
                  work with me.
                </p>
              </div>
              <div className="col">
                <PrimaryButton>Portfolio</PrimaryButton>
              </div>
            </header>
            <main>
              {posts.allMdx.edges.map(
                (post) =>
                  post.node.frontmatter.type == "portfolio" && (
                    <article key={post.node.id}>
                      <GatsbyImage
                        image={getImage(post.node.frontmatter.hero_image)}
                        alt={post.node.frontmatter.hero_image_alt}
                      />
                      <h3>
                        <Link to={"#"}>{post.node.frontmatter.title}</Link>
                      </h3>
                      <p>{post.node.frontmatter.snippet}</p>
                    </article>
                  )
              )}
            </main>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Content;
