import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PrimaryButton, SecondaryButton } from "./Buttons";
import { AiFillHtml5 } from "react-icons/ai";
import { DiCss3 } from "react-icons/di";
import { DiBootstrap } from "react-icons/di";
import { DiSass } from "react-icons/di";
import { DiJavascript1 } from "react-icons/di";
import { DiReact } from "react-icons/di";
import { SiPhp } from "react-icons/si";
import { FaLaravel } from "react-icons/fa";
import { HiDatabase } from "react-icons/hi";
import { AiFillGithub } from "react-icons/ai";
import { RiGatsbyFill } from "react-icons/ri";
import { DiWordpress } from "react-icons/di";
import { DiNpm } from "react-icons/di";
import { CgFigma } from "react-icons/cg";

const About = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="col">
          <h2>Introduction</h2>
          <p>
            I’m Saad — a web designer and developer. I design minimalist,
            clean-looking web user interfaces and develop performant web apps as
            a full stack web developer. I have experience with these
            technologies:
          </p>
          {/* <div>
            <PrimaryButton>Portfolio</PrimaryButton>
            <SecondaryButton>Contact</SecondaryButton>
          </div> */}
          <div className="skill-list">
            <ul>
              <li>
                <span>
                  <AiFillHtml5 />
                </span>
                <span className="text">HTML</span>
              </li>
              <li>
                <span>
                  <DiCss3 />
                </span>
                <span className="text">CSS</span>
              </li>
              <li>
                <span>
                  <DiBootstrap />
                </span>
                <span className="text">Bootstrap</span>
              </li>
              <li>
                <span>
                  <DiSass />
                </span>
                <span className="text">SASS</span>
              </li>
              <li>
                <span>
                  <DiJavascript1 />
                </span>
                <span className="text">JavaScript</span>
              </li>
              <li>
                <span>
                  <DiReact />
                </span>
                <span className="text">React</span>
              </li>
              <li>
                <span>
                  <SiPhp />
                </span>
                <span className="text">PHP</span>
              </li>
              <li>
                <span>
                  <FaLaravel />
                </span>
                <span className="text">Laravel</span>
              </li>
              <li>
                <span>
                  <HiDatabase />
                </span>
                <span className="text">MySQL</span>
              </li>
              <li>
                <span>
                  <AiFillGithub />
                </span>
                <span className="text">Github</span>
              </li>
              <li>
                <span>
                  <RiGatsbyFill />
                </span>
                <span className="text">Gatsby</span>
              </li>
              <li>
                <span>
                  <DiWordpress />
                </span>
                <span className="text">WordPress</span>
              </li>
              <li>
                <span>
                  <DiNpm />
                </span>
                <span className="text">NPM</span>
              </li>
              <li>
                <span>
                  <CgFigma />
                </span>
                <span className="text">Figma</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <StaticImage
            src="../images/saad.jpg"
            alt="Saad Wearing Sunglasses"
            aspectRatio={4 / 3}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
