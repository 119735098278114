import * as React from "react";
import "../styles/sass/main.scss";
import Layout from "../components/Layout";
import About from "../components/About";
import Expertise from "../components/expertise";
import Content from "../components/Content";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home — Saad Wasil">
      <About />
      <Expertise />
      <Content />
    </Layout>
  );
};

export default IndexPage;
